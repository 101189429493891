import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { RootState } from "../store";

export const OneToOneBalance: FC = (props) => {
  const currentCourseId = useSelector(
    (state: RootState) => state.student.currentCourseId
  );
  const courses = useSelector((state: RootState) => state.student.courses);
  const currentCourse = courses.find((course) => course.id === currentCourseId);
  const [total, setTotal] = useState<number>(0);
  const [rest, setRest] = useState<number>(0);
  const [restHours, setRestHours] = useState<number>(0);
  const [restMinutes, setRestMinutes] = useState<number>(0);

  useEffect(() => {
    if (currentCourse) {
      const total = !!currentCourse ? currentCourse.solde_one_to_one : 0;
      const rest = currentCourse?.formation_etudiant.soldeOneToOne;

      setTotal(total);
      setRest(rest);
      setRestHours(parseInt(String(rest / 60)));
      setRestMinutes(rest % 60);
    }
  }, [currentCourseId, courses]);

  return (
    <div style={{ maxWidth: "100%", width: "fit-content" }}>
      <div style={{ display: "flex", justifyContent: "space-between", gap: 8 }}>
        <Typography style={{ fontWeight: "bold" }}>
          <span style={{ color: "#009e4d" }}>Solde</span> One To One
        </Typography>
        <Typography style={{ color: "#23232d", fontWeight: "bold" }}>
          {restHours}H,{restMinutes} Min
        </Typography>
      </div>
      <div
        style={{
          backgroundColor: "#023047",
          padding: 8,
          borderRadius: 6,
        }}
      >
        <div style={{ display: "flex", maxWidth: "100%", overflowX: "auto" }}>
          {Array.from({ length: total / 15 }, (value, key) => key).map(
            (item) => (
              <div
                key={item}
                style={{
                  backgroundColor:
                    (item + 1) * 15 <= rest ? "#cef5fb" : "#7b939f",
                  width: 15,
                  height: 25,
                  //marginRight: item < len - 1 ? 7 : 0,
                  marginRight: 7,
                  borderRadius: 3,
                }}
              >
                &nbsp;
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
