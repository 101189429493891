import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Close, KeyboardArrowDown } from "@mui/icons-material";
import { setCurrentCourseId } from "../../reducers/student/actions";

export const SelectCurrentCourse = () => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const courses = useSelector((state: RootState) => state.student.courses);
  const dispatch = useDispatch();
  const currentCourseId =
    useSelector((state: RootState) => state.student.currentCourseId) ||
    courses[0]?.id;

  const course = courses.find((course) => course.id === currentCourseId);
  const close = () => setAnchor(null);
  const open = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchor(event.currentTarget);

  const hasManyCourses = courses.length > 1;
  if (!course) {
    return <></>;
  }

  return (
    <>
      <Button
        sx={{
          fontWeight: "bold",
          fontSize: 16,
        }}
        onClick={open}
      >
        <span style={{ color: "#023047" }}>Cour : </span>
        <span style={{ marginLeft: 4, color: "#23232d" }}>{course.title}</span>
        {hasManyCourses && <KeyboardArrowDown sx={{ color: "black" }} />}
      </Button>
      {hasManyCourses && (
        <Popover
          open={!!anchor}
          anchorEl={anchor}
          onClose={close}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack divider={<Divider />}>
            <Box p={2}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    color: "#023047",
                  }}
                >
                  Sélectionner la formation à poursuivre
                </Typography>
                <IconButton
                  size={"small"}
                  onClick={() => {
                    close();
                  }}
                >
                  <Close fontSize={"small"} />
                </IconButton>
              </Stack>
            </Box>
            <List>
              {courses.map((course) => (
                <ListItemButton key={course.id}>
                  <ListItemText
                    primary={course.title}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      color: (theme) =>
                        course.id === currentCourseId
                          ? theme.palette.primary.main
                          : "#707070",
                    }}
                    onClick={() => {
                      dispatch(setCurrentCourseId(course.id));
                      close();
                    }}
                  >
                    {course.title}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Stack>
        </Popover>
      )}
    </>
  );
};
