import React, { FC } from "react";
import { Formation } from "../../../../api/models";
import { updateFormationCreaneauByID } from "../../../../api";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { bigButton, blackAndWhite } from "../../../../styles";

function getCreditInHours(creditInMinutes: number) {
  return parseInt(String(creditInMinutes / 60));
}

export const UpdateOneToOne: FC<{
  isOpen: boolean;
  onClose: () => void;
  indexFormation: number;
  formation: Formation;
  onChange: (oneToOneCredit: number) => void;
}> = (props) => {
  const [newOneToOne, setNewOneToOne] = React.useState(
    props.formation.solde_one_to_one
  );

  const handleUpdate = () => {
    updateFormationCreaneauByID({
      id: props.formation.id,
      solde_one_to_one: newOneToOne,
    })
      .then(() => {
        props.onChange(newOneToOne);
        handleModalClose();
      })
      .catch((e) => {});
  };
  const handleModalClose = () => {
    props.onClose();
  };

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={handleModalClose}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: "#023047",
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                }}
              >
                Solde One To One
              </Typography>
              <Typography
                style={{
                  color: "#23232d",
                  fontSize: "1.15rem",
                }}
              >
                {props.formation.solde_one_to_one} MINUTES
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mt={4} mb={4}>
                <TextField
                  style={{
                    width: "100%",
                    borderColor: "#E8E8E8",
                    backgroundColor: "#FAFAFA",
                  }}
                  variant="outlined"
                  value={newOneToOne}
                  InputProps={{
                    endAdornment: <Typography>minutes</Typography>,
                  }}
                  onChange={(event) => {
                    setNewOneToOne(Number(event.target.value));
                  }}
                  InputLabelProps={{ shrink: false }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={"center"} spacing={3}>
                <Grid item>
                  <Button {...bigButton} onClick={handleModalClose}>
                    Annuler
                  </Button>
                </Grid>
                <Grid item>
                  <Button {...blackAndWhite} onClick={handleUpdate}>
                    Confirmer
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
