import * as React from "react";
import { SVGProps } from "react";

export const StepIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.49 24c1.17-.204.61-1.432 1.943-2.111a.741.741 0 0 1 .396-.106c1.494-.074 1.622 1.264 2.738.859.304-.117.588-.282.84-.487.912-.763-.183-1.544.628-2.801a.743.743 0 0 1 .286-.287c1.257-.813 2.036.287 2.798-.628.205-.253.369-.537.486-.841.407-1.115-.931-1.246-.858-2.742a.744.744 0 0 1 .106-.396c.682-1.335 1.904-.775 2.108-1.946.052-.322.052-.65 0-.973-.204-1.171-1.43-.611-2.108-1.946a.744.744 0 0 1-.106-.396c-.074-1.496 1.263-1.624.858-2.742a3.112 3.112 0 0 0-.486-.841c-.762-.912-1.542.184-2.798-.628a.743.743 0 0 1-.286-.287c-.812-1.258.286-2.038-.627-2.8a3.105 3.105 0 0 0-.84-.489c-1.115-.408-1.245.933-2.74.86a.742.742 0 0 1-.388-.124c-1.34-.68-.778-1.905-1.95-2.11a3.076 3.076 0 0 0-.972 0c-1.224.214-.558 1.537-2.132 2.2a2.876 2.876 0 0 1-.207.018c-1.494.075-1.621-1.264-2.738-.858a3.106 3.106 0 0 0-.84.487c-.912.765.184 1.551-.628 2.804a.743.743 0 0 1-.286.286c-1.257.813-2.036-.287-2.798.628-.205.253-.37.537-.486.842-.408 1.115.93 1.245.857 2.741a.744.744 0 0 1-.116.406c-.678 1.33-1.901.77-2.105 1.94-.052.323-.052.652 0 .974.204 1.17 1.427.611 2.105 1.953.072.12.108.257.106.396.074 1.496-1.263 1.624-.858 2.742.117.304.281.587.486.84.762.912 1.542-.184 2.798.628.12.067.219.166.286.286.812 1.259-.286 2.039.627 2.802.253.205.537.37.84.487 1.115.408 1.245-.933 2.74-.86a.742.742 0 0 1 .395.106c1.333.684.774 1.907 1.943 2.112"
      fill={props.fill || "#23232d"}
    />
  </svg>
);
