import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import React, { useState } from "react";
import { getStudents } from "../../../../api/course.service";
import { Etudiant } from "../../../../api/models";
import { StyledTableCell } from "../../../../component/StyledTableCell";
import { useDispatch } from "react-redux";
import { format, getUrl } from "../../../../utils/utils";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/snackbar";
import { setCurrentCourse } from "../../../../reducers/instructor/actions";

export const StudentsPage = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [students, setStudents] = useState<Etudiant[]>([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const snackbar = useSnackbar();
  const query = useQuery({
    queryKey: ["getStudentsOfCourse", courseId],
    queryFn: () =>
      getStudents(Number(courseId))
        .then((response) => {
          const { students, course } = response.data;
          setStudents(students);
          dispatch(setCurrentCourse(course));
          return students;
        })
        .catch(() => {
          snackbar.show("Erreur lors du chargement des étudiants", "error");
        }),
  });

  const filtered = students.filter((s) =>
    !!search
      ? s.user.nomComplet.toLowerCase().indexOf(search.toLowerCase()) > -1
      : true
  );

  if (query.isLoading || query.isError) {
    return <></>;
  }

  return (
    <div style={{ padding: "2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            backgroundColor: "#121212",
            color: "white",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            borderRadius: 6,
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeft />
          Retour
        </Button>
        <TextField
          variant={"standard"}
          placeholder={"Rechercher"}
          label={null}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <TableContainer
          style={{
            borderRadius: 6,
          }}
        >
          <Table
            sx={{
              border: "2px solid #E8E8E8",
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#FAFAFA",
                }}
              >
                <TableCell style={{ color: "#23232d", fontWeight: "bold" }}>
                  #
                </TableCell>
                <TableCell style={{ color: "#23232d", fontWeight: "bold" }}>
                  Nom
                </TableCell>
                <TableCell style={{ color: "#23232d", fontWeight: "bold" }}>
                  Taux d'avancement
                </TableCell>
                <TableCell style={{ color: "#23232d", fontWeight: "bold" }}>
                  Email
                </TableCell>
                <TableCell style={{ color: "#23232d", fontWeight: "bold" }}>
                  Solde One To One
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.map((student, index: number) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell>{index + 1}.</StyledTableCell>
                    <StyledTableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={getUrl(student.user.userImage)}
                          style={{
                            marginRight: "1rem",
                          }}
                        />
                        {student.user.nomComplet}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      {student.formation_etudiant.avancement}%
                    </StyledTableCell>
                    <StyledTableCell>{student.user.email}</StyledTableCell>
                    <StyledTableCell>
                      {format(student.formation_etudiant.soldeOneToOne)}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
