import { FC, ReactNode, useEffect, useState } from "react";
import { getSlots } from "../../../../api/instructor.service";
import { useSelector } from "react-redux";
import { Disponibilite } from "../../../../api/models";
import { Button, Divider, Grid, Slider, Typography } from "@mui/material";
import { ChevronLeft, Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { capitalize, format } from "../../../../utils/utils";
import { setInstructorSlots } from "../../../../api/slot.service";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../../hooks/snackbar";

export const Checkbox: FC<{
  label: ReactNode;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}> = (props) => {
  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
      }}
      onClick={() => {
        props.onChange(!props.isChecked);
      }}
    >
      <div
        style={{
          width: 20,
          height: 20,
          border: "2px solid #e8e8e8",
          backgroundColor: props.isChecked ? "#23232d" : "white",
        }}
      ></div>
      {props.label}
    </div>
  );
};

export const OneToOneBalance: FC<{
  total: number;
  occupied: number;
}> = (props) => {
  const total = props.total;
  const occupied = props.occupied;
  const rest = total - occupied;
  const restHours = parseInt(String(rest / 60));
  const restMinutes = rest % 60;
  const len = total / 15;

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography style={{ fontWeight: "bold" }}>
          <span style={{ color: "#009e4d" }}>Solde</span> One To One
        </Typography>
        <Typography
          style={{
            color: "#23232d",
            fontWeight: "bold",
            marginLeft: "0.5rem",
          }}
        >
          {format(rest)}
        </Typography>
      </div>
      <div
        style={{
          backgroundColor: "#023047",
          padding: 8,
          borderRadius: 6,
        }}
      >
        <div style={{ display: "flex" }}>
          {Array.from({ length: total / 15 }, (value, key) => key).map(
            (item, index) => (
              <div
                key={index}
                style={{
                  backgroundColor:
                    (item + 1) * 15 <= rest ? "#cef5fb" : "#7b939f",
                  width: 15,
                  height: 35,
                  marginRight: item < len - 1 ? 7 : 0,
                  borderRadius: 3,
                }}
              >
                &nbsp;
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

function isDisabled(
  total: number,
  slots: Pick<Disponibilite, "jour" | "heure_debut">[],
  day: string,
  timeStart: string
) {
  if (
    (slots.length + 1) * 15 > total &&
    !slots.find((slot) => day === slot.jour && slot.heure_debut === timeStart)
  ) {
    return true;
  }

  const maxConsecutiveSlots = 3;
  const previousSlots = Array.from(
    { length: maxConsecutiveSlots },
    (_, index) => {
      return moment(timeStart, "HH:mm:ss")
        .subtract((index + 1) * 15, "minutes")
        .format("HH:mm:ss");
    }
  );
  const nextSlots = Array.from({ length: maxConsecutiveSlots }, (_, index) => {
    return moment(timeStart, "HH:mm:ss")
      .add((index + 1) * 15, "minutes")
      .format("HH:mm:ss");
  });

  return (
    slots.filter(
      (slot) => slot.jour === day && nextSlots.includes(slot.heure_debut)
    ).length === nextSlots.length ||
    slots.filter(
      (slot) => slot.jour === day && previousSlots.includes(slot.heure_debut)
    ).length === nextSlots.length
  );
}

const SlotItem: FC<{
  isDisabled: boolean;
  isSelected: boolean;
  startTime: string;
  onSelect: () => void;
  onDeselect: () => void;
}> = (props) => {
  return (
    <div
      aria-disabled={props.isDisabled}
      style={{
        marginBottom: "0.5rem",
        padding: "0.5rem",
        borderRadius: 6,
        background: props.isDisabled
          ? "#E8E8E8"
          : props.isSelected
          ? "#009e4d"
          : "#707070",
        color: "white",
        cursor: "pointer",
      }}
      onClick={() => {
        if (!props.isDisabled) {
          props.isSelected ? props.onDeselect() : props.onSelect();
        }
      }}
    >
      <Typography>
        {props.startTime} -{" "}
        {moment(props.startTime, "HH:mm").add(15, "minutes").format("HH:mm")}
      </Typography>
    </div>
  );
};

const dayNames = [
  "lundi",
  "mardi",
  "mercredi",
  "jeudi",
  "vendredi",
  "samedi",
  "dimanche",
];

type Slot = Pick<Disponibilite, "heure_debut" | "jour" | "heure_fin">;

export const OneToOnePlanningPage = () => {
  const userId = useSelector((state: any) => state.auth.user.id);
  const navigate = useNavigate();
  const [visibleDays, setVisibleDays] = useState<string[]>([]);
  const [visibleHours, setVisibleHours] = useState<
    { hour: number; day: string }[]
  >([]);
  const [total, setTotal] = useState(0);
  const [slots, setSlots] = useState<Slot[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const snackbar = useSnackbar();

  function openTabs(slots: Slot[]) {
    slots.forEach((slot) => {
      setVisibleDays((days) => {
        if (!days.includes(slot.jour)) {
          return [...days, slot.jour];
        }
        return days;
      });
      setVisibleHours((visibleHours) => {
        return [
          ...visibleHours,
          {
            hour: parseInt(moment(slot.heure_debut, "HH").format("HH")),
            day: slot.jour,
          },
        ];
      });
    });
  }

  useEffect(() => {
    getSlots(userId)
      .then((response) => response.data)
      .then(({ total, slots }) => {
        setTotal(total);
        setSlots(slots);
        openTabs(slots);
      });
    return () => {
      setSlots([]);
      setTotal(0);
    };
  }, []);

  return (
    <div
      style={{
        margin: "1rem",
      }}
    >
      <Button
        variant={"contained"}
        style={{
          backgroundColor: "black",
          color: "white",
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ChevronLeft />
        Back
      </Button>
      <Grid
        container
        justifyContent={"space-between"}
        style={{ marginTop: "1.5rem" }}
      >
        <Grid item>
          <Typography
            style={{
              fontSize: "1.2rem",
              color: "black",
            }}
          >
            Sélectionner les créneaux horaires
          </Typography>
          <Typography
            style={{
              fontSize: "0.8rem",
            }}
          >
            <Info
              fontSize={"small"}
              style={{
                color: "#707070",
              }}
            />
            Vous avez <b>{format(total)}</b> à partager entre 2 jours au minimum
            et avec un décalage de 15 min
            <br /> entre 3 créneaux successives.
          </Typography>
          <div
            style={{ display: "flex", alignItems: "center", margin: "1rem 0" }}
          >
            <Slider
              value={total === 0 ? 0 : ((slots.length * 15) / total) * 100}
              size={"small"}
              style={{ maxWidth: 200 }}
            />
            <Typography style={{ marginLeft: "0.5rem", fontWeight: "bold" }}>
              {format(slots.length * 15)}/{format(total)}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {dayNames.map((day) => (
        <div key={day}>
          <Checkbox
            isChecked={visibleDays.includes(day)}
            onChange={(isChecked) => {
              if (isChecked) {
                setVisibleDays((visibleDays) => [...visibleDays, day]);
              } else {
                setVisibleDays((visibleDays) =>
                  visibleDays.filter((visibleDay) => visibleDay !== day)
                );
              }
            }}
            label={
              <Typography
                style={{
                  marginLeft: "0.5rem",
                  fontSize: "1.25rem",
                }}
              >
                {capitalize(day)}
              </Typography>
            }
          />
          {visibleDays.includes(day) &&
            [10, 14, 18].map((hour) => (
              <div
                key={hour}
                style={{
                  marginLeft: "1.5rem",
                  marginTop: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <Checkbox
                  isChecked={visibleHours.some(
                    (visibleHour) =>
                      visibleHour.day === day && visibleHour.hour === hour
                  )}
                  onChange={(isChecked) => {
                    if (isChecked) {
                      setVisibleHours((visibleHours) => [
                        ...visibleHours,
                        { hour, day },
                      ]);
                    } else {
                      setVisibleHours((visibleHours) =>
                        visibleHours.filter(
                          (visibleHour) =>
                            !(
                              visibleHour.hour === hour &&
                              visibleHour.day === day
                            )
                        )
                      );
                    }
                  }}
                  label={
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        marginLeft: "1rem",
                      }}
                    >
                      {hour}H-{hour + 4}H
                    </Typography>
                  }
                />
                {visibleHours.some(
                  (visibleHour) =>
                    visibleHour.hour === hour && visibleHour.day === day
                ) && (
                  <Grid container direction={"row"} spacing={2}>
                    {Array.from({ length: 16 }, (_, index) => {
                      const start = moment()
                        .set("hours", hour)
                        .set("minutes", 15 * index)
                        .set("seconds", 0);
                      return {
                        start: start.format("HH:mm:ss"),
                        end: moment(start)
                          .add(15, "minutes")
                          .format("HH:mm:ss"),
                      };
                    }).map((time, index) => (
                      <Grid item key={`${day}:${hour}:${time.start}`}>
                        <SlotItem
                          isSelected={slots.some((slot) => {
                            return (
                              slot.jour === day &&
                              slot.heure_debut === time.start
                            );
                          })}
                          isDisabled={isDisabled(total, slots, day, time.start)}
                          startTime={moment(time.start, "HH:mm").format(
                            "HH:mm"
                          )}
                          onSelect={() => {
                            setSlots((slots) => [
                              ...slots,
                              {
                                jour: day,
                                heure_debut: time.start,
                                heure_fin: time.end,
                              },
                            ]);
                          }}
                          onDeselect={() => {
                            setSlots((slots) =>
                              slots.filter((slot) => {
                                return !(
                                  slot.jour === day &&
                                  slot.heure_debut === time.start
                                );
                              })
                            );
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
            ))}
          <Divider />
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {isSaving ? (
          <LoadingButton loading={true} />
        ) : (
          <Button
            size={"large"}
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "0.5rem 2.5rem",
              marginTop: "0.5rem",
            }}
            onClick={() => {
              if (new Set(slots.map((slot) => slot.jour)).size < 2) {
                alert(
                  "Vous devez répartir les créneaux entre 2 jours miniumum"
                );
              } else {
                setIsSaving(true);
                setInstructorSlots(userId, slots).finally(() => {
                  setIsSaving(false);
                });
              }
            }}
          >
            Sauvegarder
          </Button>
        )}
      </div>
    </div>
  );
};
