import { StudentAppBar } from "../home/StudentAppBar";
import { Content } from "antd/lib/layout/layout";
import { Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { findToolById } from "../../../api/tools.service";
import { primary } from "../../../theme/colors";
import { useQuery } from "@tanstack/react-query";
import { MessagePage } from "../../../component/MessagePage";
import { Info } from "@mui/icons-material";
import { ToolCode } from "../../../api/models";
import { SimplySupportedBeamAtBothEnds } from "./simply-supported-beam-at-both-ends/SimplySupportedBeamAtBothEnds";

export const ToolsPage = () => {
  const { id } = useParams();
  const toolQuery = useQuery({
    queryKey: ["findTool"],
    queryFn: () => findToolById(Number(id)),
    enabled: typeof id !== "undefined" && !isNaN(Number(id)),
  });
  let component;
  const tool = toolQuery.data;
  if (!tool) {
    return (
      <MessagePage
        icon={<Info style={{ fontSize: "10rem" }} />}
        message={"casting not found"}
      />
    );
  }

  /*****ID = 1*****/
  if (tool.id == 1) {
    return (
      <div>
        <StudentAppBar />
        <Content>
          <Container maxWidth={"lg"}>
            {tool?.id && (
              <div>
                <Typography
                  style={{
                    fontSize: 32,
                    fontWeight:"bold",
                    color: primary,
                    margin: "2rem 0",
                  }}
                >
                  CASTING ADOLESCENTE ENTRE 13 ET 16 ANS POUR TOURNAGE FILM
                </Typography>
                <div className="casting_wrapper">
                  <img style={{ display: 'block', margin: "auto", marginBottom: 20 }}
                    src={
                      "/casting1.jpeg"
                    }
                  />
                  <p>Dans le cadre du Tournage du Film "Adieu monde cruel" de Félix De Givry, nous sommes à la recherche:</p>
                  <ul><li>D'une jeune fille douce et enthousiaste âgée entre 13 et 16 ans pour interpréter le rôle de Léna

                  </li></ul>
                  <p>Rémunération prévue.</p>
                  <p><b>Lieu:</b> Lisieux (14) </p>
                  <p><b>Date: </b> à la fin de l'été  </p>
                  <p>Pour postuler, merci de remplir le formulaire via https://gomymovie.pro/login.</p>
                  {/* <ul>
                    <li>Photos</li>
                    <li>CV</li>
                    </ul> */}
                </div>
              </div>
            )}
          </Container>
        </Content>

      </div>
    );
  }

  /*****ID = 2*****/
  if (tool.id == 2) {
    return (
      <div>
        <StudentAppBar />
        <Content>
          <Container maxWidth={"lg"}>
            {tool?.id && (
              <div>
                <Typography
                  style={{
                    fontSize: 32,
                    fontWeight:"bold",
                    color: primary,
                    margin: "2rem 0",
                  }}
                >
                  {/* {tool.title} */}
                  CASTING FEMME ENTRE 18 ET 25 ANS POUR TOURNAGE COURT MÉTRAGE
                </Typography>
                <div className="casting_wrapper">
                  <img style={{ display: 'block', margin: "auto", marginBottom: 20 }}
                    src={
                      "/casting" + tool.id + ".jpeg"
                    }
                  />
                  <p>Dans le cadre du Tournage d'un court-métrage, nous sommes à la recherche:</p>
                  <ul><li>De deux femmes âgées entre 18 et 25 ans

                  </li></ul>
                  <p><b>Synopsis:</b> Amina rentrant chez elle découvre son père déjà mort remourir face à elle mais soudain elle s’évanouit suite au choc</p>
                  <p><b>Lieu:</b> Nabeul</p>
                  <p><b>Date: </b> entre le 20 mai et le 2 juin</p>
                  <p>Pour postuler, merci d'envoyer les pièces suivantes via https://gomymovie.pro/:</p>
                  <ul>
                    <li>Photos</li>
                    <li>CV</li>
                  </ul>
                </div>
              </div>
            )}
          </Container>
        </Content>

      </div>
    );
  }

  /*****ID = 3*****/
  if (tool.id == 3) {
    return (
      <div>
        <StudentAppBar />
        <Content>
          <Container maxWidth={"lg"}>
            {tool?.id && (
              <div>
                <Typography
                  style={{
                    fontSize: 32,
                    fontWeight:"bold",
                    color: primary,
                    margin: "2rem 0",
                  }}
                >
                  {/* {tool.title} */}
                  CASTING ENFANT GARÇON ENTRE 8 ET 10 ANS POUR TOURNAGE LONG-MÉTRAGE
                </Typography>
                <div className="casting_wrapper">
                  <img style={{ display: 'block', margin: "auto", marginBottom: 20 }}
                    src={
                      "/casting" + tool.id + ".jpeg"
                    }
                  />
                  <p>D'un Enfant caucasien garçon âgé entre 8 et 10 ans, avec le teint clair et parfaitement anglophone</p>

                  <p>Rémunération prévue.
                  </p> 
                  <p><b>Lieu:</b> Sousse</p>
                  <p><b>Date: </b>courant juin </p>
                  <p>Pour postuler, merci d'envoyer les pièces suivantes via https://gomymovie.pro/:</p>
                  <ul>
                    <li>Une photo portrait</li>
                    <li>Âge de l’Enfant</li>
                    <li>Numéro de téléphone du parent</li>
                    <li>Précisez votre ville de résidence</li>
                  </ul>
                </div>
              </div>
            )}
          </Container>
        </Content>

      </div>
    );
  }

  /*****ID = 4*****/
  if (tool.id == 4) {
    return (
      <div>
        <StudentAppBar />
        <Content>
          <Container maxWidth={"lg"}>
            {tool?.id && (
              <div>
                <Typography
                  style={{
                    fontSize: 32,
                    fontWeight:"bold",
                    color: primary,
                    margin: "2rem 0",
                  }}
                >
                  {/* {tool.title} */}
                  CASTING HOMME ET FEMME ENTRE 25 ET 50 ANS POUR TOURNAGE SPOT PUBLICITAIRE
                </Typography>
                <div className="casting_wrapper">
                  <img style={{ display: 'block', margin: "auto", marginBottom: 20 }}
                    src={
                      "/casting" + tool.id + ".jpeg"
                    }
                  />
                  <p>Dans le cadre du Tournage d'un spot publicitaire télévisé pour une enseigne de garage automobile, nous sommes à la recherche:</p>
                  <ul>
                    <li>D'un comédien âgé entre 30 et 50 ans pour interpréter le rôle d'un garagiste</li>
                    <li>D'une comédienne rousse âgée entre 25 et 45 ans pour interpréter le rôle d’une cliente</li>
                    <li>D'une figurante d’arrière plan âgée entre 25 et 45 ans pour interpréter le rôle d'une garagiste</li>
                    
                    </ul>
                    <p>Rémunération prévue.</p>
                  <p><b>Lieu:</b> Tunis</p>
                  <p><b>Date: </b> 22 juin</p>
                  <p>Pour postuler, merci d'envoyer les pièces suivantes via https://gomymovie.pro/:</p>
                  <ul>
                    <li>CV</li>
                    <li>Bande démo (si vous avez)</li>
                    <li>Photos portrait</li>
                  </ul>
                </div>
              </div>
            )}
          </Container>
        </Content>

      </div>
    );
  }

  /*****ID = 5*****/
  if (tool.id == 5) {
    return (
      <div>
        <StudentAppBar />
        <Content>
          <Container maxWidth={"lg"}>
            {tool?.id && (
              <div>
                <Typography
                  style={{
                    fontSize: 32,
                    fontWeight:"bold",
                    color: primary,
                    margin: "2rem 0",
                  }}
                >
                  {/* {tool.title} */}
                  CASTING HOMME ENTRE 16 ET 60 ANS POUR TOURNAGE FILM ACTION AVEC SOFIANE ZERMANI ET VICTOR BELMONDO
                </Typography>
                <div className="casting_wrapper">
                  <img style={{ display: 'block', margin: "auto", marginBottom: 20 }}
                    src={
                      "/casting" + tool.id + ".jpeg"
                    }
                  />
                  <p>Dans le cadre du Tournage du Film "Bastion 36" d'Olivier Marchal pour Netflix avec Sofiane Zermani alias Fianso, Victor Belmondo, Yvan Attal, Soufiane Guerrab, Tewfik Jallab et Isabelle Dol, nous sommes à la recherche:
</p>
                  <ul><li>De 100 hommes âgés entre 16 et 60 ans, de toutes origines, profils populaires, gueules cassées, physiques atypiques pour interpréter des spectateurs de combats clandestins

                  </li></ul>
                  <p>Rémunération prévue.</p>
                  <p><b>Lieu:</b> Paris</p>
                  <p><b>Date: </b>13 mai</p>
                  <p>Pour postuler, merci d'envoyer les pièces suivantes via https://gomymovie.pro/:</p>
                  <ul>
                    <li>Photos récentes (portrait, en pied et selfie du jour)</li>
                    <li>Numéro de téléphone</li>
                    <li>Mensurations</li>
                    <li>Lieu de résidence</li>
                  </ul>
                </div>
              </div>
            )}
          </Container>
        </Content>

      </div>
    );
  }

  /*****ID = 6*****/
  

  if (tool.code === ToolCode.SIMPLY_SUPPORTED_BEAM_AT_BOTH_ENDS) {
    component = <SimplySupportedBeamAtBothEnds />;
  }



  if (!component) {
    return (
      <MessagePage
        icon={<Info style={{ fontSize: "10rem" }} />}
        message={"Tool not implemented yet"}
      />
    );
  }

  return (
    <div>
      <StudentAppBar />
      <Content>
        <Container maxWidth={"lg"}>
          {!tool && <Typography variant={"h5"}>Casting inconnu</Typography>}
          {tool?.id && (
            <div>
              <Typography
                style={{
                  fontSize: 32,
                  fontWeight:"bold",
                  color: primary,
                  margin: "2rem 0",
                }}
              >
                {/* {tool.title} */}
                Casting inconnu
              </Typography>
              {/* {component} */}
              {/* return <ReturnContent /> */}
            </div>
          )}
        </Container>
      </Content>
    </div>
  );
};
