import React, { FC, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getUrl } from "../../../utils/utils";
import { Formateur } from "../../../api/models";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { getAll, update } from "../../../api/instructor.service";
import { useSnackbar } from "../../../hooks/snackbar";
import { LoadingButton } from "@mui/lab";
import { addInstructor } from "../../../routes/admin.routes";

export const StyledTableCell: FC<TableCellProps> = (props) => {
  return (
    <TableCell
      {...props}
      style={{
        borderBottom: "2px solid #E8E8E8",
        color: "#023047",
        fontSize: "16px",
        ...props.style,
      }}
    />
  );
};

const AddInstructorButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant={"contained"}
      sx={{
        backgroundColor: "black",
        color: "white",
        padding: ".5rem 3rem",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      }}
      onClick={() => {
        navigate(addInstructor);
      }}
    >
      <Add />
      Ajouter formateur
    </Button>
  );
};

const UpdateOneToOne: FC<{
  instructor: Formateur;
  onUpdate: () => void;
}> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const snackbar = useSnackbar();
  const mutation = useMutation({
    mutationKey: ["updateInstructor", props.instructor.id],
    mutationFn: (values: { soldeOneToOne: number }) =>
      update(props.instructor.id, values)
        .then(() => {
          props.onUpdate();
          setIsOpen(false);
        })
        .catch(() => {
          snackbar.show("Erreur serveur", "error");
        }),
  });
  const form = useForm<{
    soldeOneToOne: number;
  }>({
    defaultValues: {
      soldeOneToOne: props.instructor.soldeOneToOne,
    },
    resolver: yupResolver(
      yup.object().shape({
        soldeOneToOne: yup.number().min(0, "Le minimum est 0 minutes"),
      })
    ),
  });
  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {props.instructor.soldeOneToOne} minutes
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <form
          onSubmit={form.handleSubmit((values) => {
            mutation.mutate(values);
          })}
        >
          <DialogTitle>Solde one to one</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <TextField
                label={"Solde one to one (minutes)"}
                inputProps={{
                  step: 15,
                }}
                type={"number"}
                {...form.register("soldeOneToOne")}
                error={!!form.formState.errors.soldeOneToOne}
                helperText={form.formState.errors.soldeOneToOne?.message}
              >
                {props.instructor.soldeOneToOne}
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={mutation.isLoading} type={"submit"}>
              Valider
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

type Instructor = Formateur & { course?: string };

export function InstructorList() {
  const instructorsQuery = useQuery({
    queryKey: ["all-instructors"],
    queryFn: () => getAll().then((response) => response.data),
  });

  if (
    instructorsQuery.isLoading ||
    instructorsQuery.isError ||
    !instructorsQuery.data
  ) {
    return <></>;
  }
  const instructors = instructorsQuery.data;

  return (
    <Box p={3}>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <AddInstructorButton />
      </Box>
      <TableContainer
        style={{
          borderRadius: 6,
        }}
      >
        <Table
          sx={{
            border: "2px solid #E8E8E8",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#FAFAFA",
                "& th": {
                  color: "#23232d",
                  fontWeight: "bold",
                  fontSize:"1rem",
                  padding:"4px 15px"
                },
              }}
            >
              <TableCell>Nom complet</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>CIN</TableCell>
              <TableCell>Telephone</TableCell>
              <TableCell>Formation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instructors.map((instructor) => (
              <TableRow key={instructor.id}>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <Avatar src={getUrl(instructor.user.userImage)} />
                    {instructor.user.nomComplet}
                  </div>
                </StyledTableCell>
                <StyledTableCell>{instructor.user.email}</StyledTableCell>
                <StyledTableCell>{instructor.user.adresse}</StyledTableCell>
                <StyledTableCell>{instructor.user.cin}</StyledTableCell>
                <StyledTableCell>{instructor.user.tel}</StyledTableCell>
                <StyledTableCell>
                  <ul>
                    {instructor.formations.map((course) => (
                      <li key={course.id}>{course.title}</li>
                    ))}
                  </ul>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
