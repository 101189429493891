import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Formation, Question } from "../../../../../api/models";
import { useSnackbar } from "../../../../../hooks/snackbar";
import {
  addQuestion,
  deleteByVersion,
  deleteQuestion,
  updateQuestion,
} from "../../../../../api/question.service";
import produce from "immer";
import { updateQuiz } from "../../../../../api/quiz.service";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { MinimumScore } from "./MinimumScore";
import { ScoresTab } from "./ScoresTab";
import {
  addAnswer,
  deleteAnswer,
  updateAnswer,
} from "../../../../../api/answer.service";
import { Add, Delete } from "@mui/icons-material";
import { QuizSelection } from "./_types";

export const QuizContent: FC<{
  selection: QuizSelection;
  setCourse: Dispatch<SetStateAction<Formation | null>>;
}> = (props) => {
  const snackbar = useSnackbar();
  const { selection } = props;
  const [currentVersion, setCurrentVersion] = useState(1);
  const { quiz, chapterIndex, subChapterIndex } = selection;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const versionQuestions = quiz.questions.filter(
    (question) => question.version === currentVersion
  );
  const [versions, setVersions] = useState(
    new Set(quiz.questions.map((q) => q.version)).size
  );

  useEffect(() => {
    setVersions(new Set(quiz.questions.map((q) => q.version)).size);
  }, [quiz.questions]);

  useEffect(() => {
    setCurrentVersion(1);
  }, [quiz.id]);

  function handleDeleteQuizVersion() {
    setIsDeleteDialogOpen(false);
    deleteByVersion(quiz.id, currentVersion)
      .then((response) => {
        props.setCourse(
          produce((course) => {
            if (!!course) {
              course.chapitres[chapterIndex].souschapitres[
                subChapterIndex
              ].qcm.questions = response.data;
            }
          })
        );
        setCurrentVersion(1);
        snackbar.show("Version supprimé avec succès", "info");
      })
      .catch((e) => {
        snackbar.show("Erreur lors de la suppression de la version", "success");
      });
  }

  function handleUpdateScore(newScore: number) {
    updateQuiz(quiz.id, { score: newScore })
      .then(() => {
        props.setCourse(
          produce((course) => {
            if (!!course) {
              course.chapitres[selection.chapterIndex].souschapitres[
                selection.subChapterIndex
              ].qcm.score = newScore;
            }
          })
        );
      })
      .catch((e) => {
        snackbar.show("Erreur lors de la modification du score", "error");
      });
  }

  function getQuestionIndex(question: Question) {
    return quiz.questions.findIndex((q) => q.id === question.id);
  }

  return (
    <div>
      <Dialog open={isDeleteDialogOpen}>
        <DialogTitle>Suppression du Quiz</DialogTitle>
        <DialogContent>
          Voulez-vous vraiment supprimer la <b>version {currentVersion}</b> du
          Quiz?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteDialogOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              handleDeleteQuizVersion();
            }}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        spacing={4}
        alignItems={"flex-end"}
        style={{
          marginBottom: "1rem",
        }}
      >
        <Grid item>
          <MinimumScore
            score={quiz.score}
            onChange={(newScore) => {
              handleUpdateScore(newScore);
            }}
          />
        </Grid>
        <Grid item>
          <ScoresTab
            versions={versions}
            currentVersion={currentVersion}
            onSelect={(version) => {
              setCurrentVersion(version);
            }}
            onAdd={() => {
              const newVersion = versions + 1;
              setVersions(newVersion);
              setCurrentVersion(newVersion);
            }}
          />
        </Grid>
      </Grid>
      <div>
        {versionQuestions.map((question, questionIndex) => (
          <div
            key={question.id}
            style={{
              border: "1px solid #707070",
              borderRadius: 10,
              padding: "2rem",
              marginBottom: "1.5rem",
            }}
          >
            <div style={{ marginBottom: "1.5rem" }}>
              <input
                style={{
                  color: "#121212",
                  width: "100%",
                }}
                placeholder={`Question ${questionIndex + 1}`}
                defaultValue={question.question}
                onBlur={(event) => {
                  updateQuestion(question.id, {
                    question: event.target.value,
                    version: currentVersion,
                  }).then();
                }}
                onChange={(e) => {
                  props.setCourse(
                    produce((course) => {
                      if (!!course) {
                        course.chapitres[chapterIndex].souschapitres[
                          subChapterIndex
                        ].qcm.questions[getQuestionIndex(question)].question =
                          e.target.value;
                      }
                    })
                  );
                }}
              />
            </div>
            <div>
              {question.Reponses.map((answer, answerIndex) => (
                <div
                  key={answer.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #707070",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <div>
                      {/*<IconButton>*/}
                      {/*  <DragHandle />*/}
                      {/*</IconButton>*/}
                    </div>
                    <Checkbox
                      checked={answer.estElleValide === 1}
                      value={answer.estElleValide}
                      onChange={(event, isChecked) => {
                        updateAnswer(answer.id, {
                          estElleValide: isChecked ? 1 : 0,
                        }).then(() => {
                          props.setCourse(
                            produce((course) => {
                              if (!!course) {
                                const questionIndex = quiz.questions.findIndex(
                                  (c) => c.id === question.id
                                );
                                course.chapitres[chapterIndex].souschapitres[
                                  subChapterIndex
                                ].qcm.questions[
                                  getQuestionIndex(question)
                                ].Reponses[answerIndex].estElleValide =
                                  isChecked ? 1 : 0;
                              }
                            })
                          );
                        });
                      }}
                    />
                    <input
                      type={"text"}
                      style={{
                        marginLeft: "0.5rem",
                        border: "none",
                        flex: 1,
                      }}
                      placeholder={`Réponse ${answerIndex + 1}`}
                      value={answer.reponse}
                      onBlur={(event) => {
                        const answerValue = event.target.value;
                        updateAnswer(answer.id, {
                          reponse: answerValue,
                        }).then(() => {});
                      }}
                      onChange={(e) => {
                        props.setCourse(
                          produce((course) => {
                            if (!!course) {
                              course.chapitres[chapterIndex].souschapitres[
                                subChapterIndex
                              ].qcm.questions[
                                getQuestionIndex(question)
                              ].Reponses[answerIndex].reponse = e.target.value;
                            }
                          })
                        );
                      }}
                    />
                  </div>
                  {question.Reponses.length > 1 && (
                    <div>
                      <IconButton
                        onClick={() => {
                          deleteAnswer(answer.id).then(() => {});
                          props.setCourse(
                            produce((course) => {
                              if (!!course) {
                                course.chapitres[chapterIndex].souschapitres[
                                  subChapterIndex
                                ].qcm.questions[
                                  getQuestionIndex(question)
                                ].Reponses = question.Reponses.filter(
                                  (_answer) => _answer.id !== answer.id
                                );
                              }
                            })
                          );
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                cursor: "pointer",
              }}
              onClick={() => {
                addAnswer(question.id, "", false)
                  .then((response) => {
                    props.setCourse(
                      produce((course) => {
                        if (!!course) {
                          course.chapitres[chapterIndex].souschapitres[
                            subChapterIndex
                          ].qcm.questions[getQuestionIndex(question)].Reponses =
                            [...question.Reponses, response.data];
                        }
                      })
                    );
                  })
                  .catch(() => {
                    snackbar.show(
                      "Erreur lors de l'ajout de la réponse",
                      "error"
                    );
                  });
              }}
            >
              <Checkbox disabled={true} />
              <div style={{ marginLeft: "0.5rem", color: "#707070" }}>
                Ajouter une réponse
              </div>
            </div>
            <Grid
              container
              justifyContent={"flex-end"}
              alignItems={"center"}
              spacing={3}
            >
              {versionQuestions.length > 1 && (
                <Grid item>
                  <IconButton
                    onClick={() => {
                      deleteQuestion(question.id).then(() => {
                        props.setCourse(
                          produce((course) => {
                            if (!!course) {
                              course.chapitres[chapterIndex].souschapitres[
                                subChapterIndex
                              ].qcm.questions = quiz.questions.filter(
                                (_question) => _question.id !== question.id
                              );
                            }
                          })
                        );
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </div>
        ))}
      </div>
      <div>
        <Button
          style={{
            color: "#23232d",
            backgroundColor: "#CDE8F4",
            fontWeight: "bold",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
          onClick={() => {
            addQuestion(quiz.id, "", currentVersion)
              .then((response) => response.data)
              .then((question) => {
                props.setCourse(
                  produce((course) => {
                    if (!!course) {
                      course.chapitres[chapterIndex].souschapitres[
                        subChapterIndex
                      ].qcm.questions.push({
                        ...question,
                        Reponses: [],
                      });
                    }
                  })
                );
              })
              .catch(() => {
                snackbar.show("Erreur lors de l'ajout de la question", "error");
              });
          }}
        >
          <Add fontSize={"small"} />
          Ajouter Question
        </Button>
      </div>
      <div>
        {versions > 1 && (
          <Grid
            container
            justifyContent={"flex-end"}
            style={{ marginTop: "1rem" }}
          >
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#E63946",
                  color: "white",
                  paddingLeft: "3rem",
                  paddingRight: "3rem",
                }}
                onClick={() => {
                  setIsDeleteDialogOpen(true);
                }}
              >
                Supprimer
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};
