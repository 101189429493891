import * as React from "react";
import { FC, useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";

export const MinimumScore: FC<{
  score: number;
  onChange: (newScore: number) => void;
}> = (props) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const closeMenu = () => {
    setAnchor(null);
  };
  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchor(event.currentTarget);
  };

  return (
    <div
      style={{
        background: "#023047",
        border: "2px solid #E8E8E8",
        borderRadius: 6,
        width: "fit-content",
        padding: "0.3rem 0.5rem",
        color: "#FAFAFA",
        display: "flex",
        justifyContent: "space-between",
        fontSize: "1rem",
        alignItems: "center",
      }}
    >
      <div style={{ marginRight: "3rem" }}>Score minimum pour réussir</div>
      <div
        style={{
          fontWeight: "bold",
          color: "#23232d",
          display: "flex",
          cursor: "pointer",
        }}
        onClick={openMenu}
      >
        {props.score}%
        <KeyboardArrowDown style={{ color: "#23232d" }} />
      </div>
      <Menu open={!!anchor} onClose={closeMenu} anchorEl={anchor}>
        {Array.from({ length: 10 }, (_, index) => (index + 1) * 10).map(
          (score) => (
            <MenuItem
              style={{
                fontWeight: score === props.score ? "bold" : "normal",
                color: score === props.score ? "#23232d" : undefined,
              }}
              key={score}
              onClick={() => {
                props.onChange(score);
                closeMenu();
              }}
            >
              {score}%
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};
