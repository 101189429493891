import * as React from "react";
import { FC } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { Add } from "@mui/icons-material";

export const ScoresTab: FC<{
  versions: number;
  currentVersion: number;
  onAdd: () => void;
  onSelect: (version: number) => void;
}> = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        {Array.from({ length: props.versions }, (_, i) => i + 1).map(
          (version) => (
            <Grid item key={version}>
              <Button
                disableElevation={true}
                variant={
                  props.currentVersion === version ? "outlined" : "contained"
                }
                style={{
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  color: "#23232d",
                  borderColor:
                    props.currentVersion === version ? "#23232d" : undefined,
                  background:
                    props.currentVersion === version ? "#CDE8F4" : "white",
                }}
                onClick={() => {
                  if (props.currentVersion !== version) {
                    props.onSelect(version);
                  }
                }}
              >
                Version {version}
              </Button>
              <div style={{ padding: "0 2rem" }}>
                <Divider
                  orientation={"vertical"}
                  style={{
                    borderColor: "#23232d",
                  }}
                />
              </div>
            </Grid>
          )
        )}
        <Grid item>
          <Button
            variant={"outlined"}
            disableElevation={true}
            disabled={props.versions === 3}
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              color: "#23232d",
              borderColor: "#23232d",
              "&.Mui-disabled": {
                color: "#E8E8E8",
                borderColor: "#E8E8E8",
              },
            }}
            onClick={() => {
              props.onAdd();
            }}
          >
            <Add />
            Ajouter version
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
