import {
  Box,
  Button,
  Grid,
  Step as StepMui,
  Stepper,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import StepLabel from "@mui/material/StepLabel";
import { useDispatch, useSelector } from "react-redux";

import { fetchFormation } from "../../../actions/formation";
import { getGeneralProgessionFormationByStudent } from "../../../api/index";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { PendingActionsRounded } from "@mui/icons-material";
import { getInfoProjectStudent } from "../../../api/project.service";

import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { submitProject } from "../../../api/student-project.service";
import { useSnackbar } from "../../../hooks/snackbar";
import { RootState } from "../../../store";

const formatDate = (date: string) =>
  moment(date).format("ddd, DD MMMM YYYY, h:mm");

export const Projects = () => {
  const snackbar = useSnackbar();
  const [file, setFile] = useState<File | null>(null);
  const submitProjectMutation = useMutation({
    mutationKey: ["submitProject"],
    mutationFn: (values: { file: File; projectId: number }) =>
      submitProject(values.file, values.projectId)
        .then(() => {
          setFile(null);
          snackbar.show("Projet soumis avec succès", "success");
        })
        .catch(() => {
          snackbar.show("Erreur de la soumission du projet", "error");
        }),
  });
  const dispatch = useDispatch();

  const currentFormation = useSelector((state: RootState) => {
    return state.student.currentCourseId || null;
  });

  const [generalProgression, setGeneralProgression] = useState<{
    total: number;
    activeChapitre: number;
    activeSousChapitre: number;
    nextSousChapitre: number;
    nextChapitre: number;
    estProjetTermine: boolean;
  }>({
    total: -1,
    activeChapitre: -1,
    activeSousChapitre: -1,
    nextSousChapitre: -1,
    nextChapitre: -1,
    estProjetTermine: false,
  });

  const [progressionLoadInfo, setProgressionLoadInfo] = useState<boolean>(true);

  const [projectInfo, setProjectInfo] = useState<{
    note: number | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    estValide: boolean | null;
  }>({
    note: null,
    createdAt: null,
    updatedAt: null,
    estValide: null,
  });

  const [currentProject, setCurrentProject] = useState(0);
  const ref = useRef<HTMLInputElement>();
  const cours = useSelector((state) => {
    return (state as any).formation?.formation;
  });

  useEffect(() => {
    if (currentFormation != null)
      dispatch<any>(fetchFormation(currentFormation));
  }, [currentFormation]);

  useEffect(() => {
    if (cours != null)
      getGeneralProgessionFormationByStudent(currentFormation).then(
        (data: any) => {
          setGeneralProgression({
            total: data.data.total,
            activeChapitre: data.data.nextChapitre - 1,
            activeSousChapitre:
              data.data.activeSousChapitre === data.data.nextSousChapitre &&
              !data.data.estProjetTermine
                ? data.data.activeSousChapitre === 1
                  ? 0
                  : data.data.nextSousChapitre + 1
                : data.data.nextSousChapitre - 1,
            nextSousChapitre:
              data.data.activeSousChapitre === data.data.nextSousChapitre &&
              !data.data.estProjetTermine
                ? data.data.activeSousChapitre === 1
                  ? 0
                  : data.data.nextSousChapitre + 1
                : data.data.nextSousChapitre - 1,
            nextChapitre: data.data.nextChapitre - 1,
            estProjetTermine: data.data.estProjetTermine,
          });
          if (
            generalProgression.activeChapitre > -1 &&
            cours?.chapitres[generalProgression.activeChapitre]?.project.id !==
              undefined
          ) {
            getInfoProjectStudent(
              cours?.chapitres[generalProgression.activeChapitre]?.project.id
            ).then((data) => {
              if (data !== "")
                setProjectInfo({
                  note: data.note,
                  createdAt: data.createdAt,
                  updatedAt: data.updatedAt,
                  estValide:
                    data.estValide === 0
                      ? false
                      : data.estValide === 1
                      ? true
                      : null,
                });
              else {
                setProjectInfo({
                  note: null,
                  createdAt: null,
                  updatedAt: null,
                  estValide: null,
                });
              }
            });
            setProgressionLoadInfo(false);
          }
        }
      );
  }, [cours]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const projectId =
      cours?.chapitres[generalProgression.activeChapitre]?.project.id;
    if (!!file) {
      submitProjectMutation.mutate({ file, projectId });
    } else {
      snackbar.show("Aucun fichier sélectionné", "info");
    }
  };

  useEffect(() => {
    handleChapter();
    setProgressionLoadInfo(false);
  }, [generalProgression.activeChapitre]);

  const handleChapter = () => {
    getInfoProjectStudent(
      cours?.chapitres[generalProgression.activeChapitre]?.project.id
    ).then((data) => {
      if (data !== "")
        setProjectInfo({
          note: data.note,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          estValide:
            data.estValide === 0 ? false : data.estValide === 1 ? true : null,
        });
      else {
        setProjectInfo({
          note: null,
          createdAt: null,
          updatedAt: null,
          estValide: null,
        });
      }
    });
  };

  return (
    <Grid container className={"p-5"}>
      <Grid item xs={2}>
        <Grid container>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper
              activeStep={generalProgression.activeChapitre}
              orientation="vertical"
            >
              {cours?.chapitres?.map((step: any, index: number) => (
                <StepMui key={index}>
                  <StepLabel
                    onClick={() => {
                      if (index <= generalProgression.nextChapitre) {
                        setProgressionLoadInfo(true);
                        setGeneralProgression((old) => {
                          return {
                            ...old,
                            activeChapitre: index,
                          };
                        });
                        setCurrentProject(0);
                      }
                    }}
                  >
                    <span
                      style={{
                        color:
                          index <= generalProgression.nextChapitre
                            ? "#23232d"
                            : "black",
                        fontWeight:
                          index <= generalProgression.nextChapitre
                            ? "500"
                            : "400",
                      }}
                    >
                      {step.title}
                    </span>
                  </StepLabel>
                </StepMui>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Grid>
      {progressionLoadInfo === false && (
        <Grid item xs={10} style={{ flexGrow: 1 }}>
          <Box className={"justify-content-center container"}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentProject}
                onChange={(event, value) => {
                  setCurrentProject(value);
                }}
              >
                <Tab
                  key={"Details"}
                  label="Détails"
                  style={{
                    fontSize: 18,
                    textTransform: "none",
                  }}
                />
                <Tab
                  key={"Instructions"}
                  label="Instructions"
                  style={{
                    fontSize: 18,
                    textTransform: "none",
                  }}
                />
                {!!generalProgression &&
                  generalProgression.activeChapitre >=
                    generalProgression.nextChapitre && (
                    <Tab
                      key={"Projet"}
                      label="Projet"
                      style={{ fontSize: 18, textTransform: "none" }}
                    />
                  )}
              </Tabs>
            </Box>
            <div
              className={"mt-4 text-left p-5"}
              style={{
                borderRadius: 25,
                border: "1px solid #707070",
              }}
            >
              {currentProject === 0 && (
                <>
                  <div className={"d-flex justify-content-between mb-2"}>
                    <h4
                      style={{
                        color: "#023047",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                      }}
                    >
                      Projet&nbsp;
                      <span
                        style={{
                          color: "#23232d",
                        }}
                      >
                        statut
                      </span>
                      :
                    </h4>
                    <span
                      className="badge badge-pill badge-success py-2 px-3"
                      style={{
                        fontSize: "1rem",
                        backgroundColor:
                          projectInfo.createdAt !== null &&
                          projectInfo.updatedAt !== null
                            ? projectInfo.estValide === true
                              ? "#BCF0DA"
                              : projectInfo.estValide === false
                              ? "#FBD5D5"
                              : "#F6F2FF"
                            : new Date() >
                              new Date(
                                cours?.chapitres[
                                  generalProgression.activeChapitre
                                ]?.project.deadline
                              )
                            ? "#FBD5D5"
                            : "#FCE96A",
                        color:
                          projectInfo.createdAt !== null &&
                          projectInfo.updatedAt !== null
                            ? projectInfo.estValide === true
                              ? "#014737"
                              : projectInfo.estValide === false
                              ? "#771D1D"
                              : "#7C54D6"
                            : new Date() >
                              new Date(
                                cours?.chapitres[
                                  generalProgression.activeChapitre
                                ]?.project.deadline
                              )
                            ? "#771D1D"
                            : "#AA873B",
                      }}
                    >
                      {projectInfo.createdAt !== null &&
                      projectInfo.updatedAt !== null
                        ? projectInfo.estValide === true
                          ? "success"
                          : projectInfo.estValide === false
                          ? "Réfusé"
                          : "Déposé"
                        : new Date() >
                          new Date(
                            cours?.chapitres[
                              generalProgression.activeChapitre
                            ]?.project.deadline
                          )
                        ? "Manquant"
                        : "Pas encore"}
                    </span>
                  </div>
                  <hr
                    style={{
                      color: "gray",
                    }}
                  />
                  <div className={"row mt-5"}>
                    <div className={"col-12"}>
                      <div className={"row"}>
                        <div
                          className={"col-2 col-xs-12 fw-bold"}
                          style={{ color: "#023047", fontSize: ".95rem" }}
                        >
                          Deadline
                        </div>
                        <div
                          className={"col-10 col-xs-12 fw-bold"}
                          style={{ color: "#121212", fontSize: ".95rem" }}
                        >
                          {generalProgression.activeChapitre >= 0 &&
                            !!cours?.chapitres[
                              generalProgression.activeChapitre
                            ]?.project.deadline &&
                            formatDate(
                              cours?.chapitres[
                                generalProgression.activeChapitre
                              ]?.project.deadline
                            )}
                        </div>
                      </div>
                    </div>
                    <hr
                      className={"w-25 col-12 ml-2 my-2"}
                      style={{
                        color: "gray",
                        border: "1px solid gray",
                      }}
                    />
                    <div className={"col-12"}>
                      <div className={"row"}>
                        <div
                          className={"col-2 col-xs-12 fw-bold"}
                          style={{ color: "#023047", fontSize: ".95rem" }}
                        >
                          Date de dépôt
                        </div>
                        <div
                          className={"col-10 col-xs-12"}
                          style={{ color: "#121212", fontSize: ".95rem" }}
                        >
                          {projectInfo.createdAt !== undefined &&
                          projectInfo.createdAt !== null
                            ? formatDate(projectInfo.createdAt + "")
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <hr
                      className={"w-25 col-12 ml-2 my-2"}
                      style={{
                        color: "gray",
                        border: "1px solid gray",
                      }}
                    />
                    <div className={"col-12"}>
                      <div className={"row"}>
                        <div
                          className={"col-2 col-xs-12 fw-bold"}
                          style={{ color: "#023047", fontSize: ".95rem" }}
                        >
                          Traité le
                        </div>
                        <div
                          className={"col-10 col-xs-12 "}
                          style={{ color: "#121212", fontSize: ".95rem" }}
                        >
                          {projectInfo.updatedAt !== undefined &&
                          projectInfo.updatedAt !== null &&
                          projectInfo.estValide !== null
                            ? formatDate(projectInfo.updatedAt + "")
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <hr
                      className={"w-25 col-12 ml-2 my-2"}
                      style={{
                        color: "gray",
                        border: "1px solid gray",
                      }}
                    />

                    <div className={"col-12"}>
                      <div className={"row"}>
                        <div
                          className={"col-1 col-xs-6 fw-bold"}
                          style={{ color: "#023047", fontSize: ".95rem" }}
                        >
                          Valide
                        </div>
                        <div
                          className={"col-1 col-xs-6"}
                          style={{ color: "#121212", fontSize: ".95rem" }}
                        >
                          {!!projectInfo && projectInfo.estValide === true && (
                            <CheckRoundedIcon
                              fontSize={"small"}
                              style={{
                                color: "#23232d",
                              }}
                            />
                          )}

                          {!!projectInfo && projectInfo.estValide === false && (
                            <CloseRoundedIcon
                              fontSize={"small"}
                              style={{
                                color: "#FC6180",
                              }}
                            />
                          )}

                          {!!projectInfo && projectInfo.estValide === null && (
                            <PendingActionsRounded
                              fontSize={"small"}
                              style={{
                                color: "#ff9500",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {currentProject === 1 && (
                <div>
                  <h4
                    style={{
                      color: "#023047",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Instructions
                  </h4>
                  <p
                    style={{
                      fontSize: ".9rem",
                    }}
                    className={"mt-2"}
                  >
                    {!!cours?.chapitres &&
                      cours?.chapitres[generalProgression.activeChapitre]
                        ?.project &&
                      cours?.chapitres[generalProgression.activeChapitre]
                        ?.project?.enonce && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              cours?.chapitres[
                                generalProgression.activeChapitre
                              ]?.project.enonce,
                          }}
                        />
                      )}
                  </p>
                </div>
              )}
              {currentProject === 2 && (
                <Grid container>
                  <Grid item xs={12}>
                    <h4
                      style={{
                        color: "#023047",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Instructions
                    </h4>
                  </Grid>
                  <Grid item xs={12} className={"mt-3"}>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <Grid container spacing={1}>
                        <Grid item xs={10}>
                          <input
                            ref={ref as any}
                            type="file"
                            hidden={true}
                            onChange={(e) => {
                              if (!!e.target.files?.[0]) {
                                setFile(e.target.files[0]);
                              }
                            }}
                          />
                          <Button
                            style={{
                              textTransform: "none",
                              fontWeight: "bold",
                              border: "1px solid #707070",
                              borderRadius: 6,
                              marginRight: 9,
                              color: "black",
                            }}
                            fullWidth
                            onClick={() => {
                              ref?.current?.click();
                            }}
                          >
                            Sélectionner le fichier
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            type={"submit"}
                            style={{
                              textTransform: "none",
                              fontWeight: "bold",
                              color: "white",
                              backgroundColor: "black",
                              border: "1px solid black",
                              borderRadius: 6,
                              width: "100%",
                            }}
                            className={"px-5"}
                            disabled={submitProjectMutation.isLoading}
                          >
                            Confirmer
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item xs={12} className={"mt-4"}>
                    <hr
                      style={{
                        color: "gray",
                      }}
                    />
                    <ul
                      style={{
                        listStyle: "initial",
                        fontWeight: "bold",
                        fontSize: ".75rem",
                      }}
                      className={"ml-4 fw-bold mt-3"}
                    >
                      <li>Un seul fichier pour le dépôt</li>
                      <li>
                        Sélectionnez le fichier de type: PDF, Images, Zip, RAR,
                        DWG.
                      </li>
                      <li>Fichier de taille maximal 15M</li>
                    </ul>
                  </Grid>
                </Grid>
              )}
            </div>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
