import React, { ReactNode, useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Layout } from "antd";
import { SideBarButton } from "./SideBarButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setSiderCollapse } from "../reducers/layout.reducer";
import { UserAppBar } from "./UserAppBar";
import { Link } from "react-router-dom";

const { Content, Sider } = Layout;

export function UserLayout(props: {
  logoLink: string;
  toolbar: ReactNode;
  menuItems: { text: string; icon: any; link: string }[];
  menuItems2?: { text: string; icon: any; link: string }[];
  content: ReactNode;
}) {
  const theme = useTheme();
  const isDown = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isCollapsed
  );
  useEffect(() => {
    if (isDown) {
      dispatch(setSiderCollapse({ isCollapsed: true }));
    } else {
      dispatch(setSiderCollapse({ isCollapsed: false }));
    }
  }, [isDown]);

  return (
    <Layout style={{ height: "100vh" }}>
      <div className={isCollapsed ? "sider-nav" : ""}>
        <Sider
          trigger={null}
          width={"18vw"}
          collapsible
          collapsed={isCollapsed}
          onCollapse={(isCollapsed) => {
            dispatch(
              setSiderCollapse({
                isCollapsed,
              })
            );
          }}
          style={{ height: "100vh" }}
        >
          <div
            style={{
              backgroundColor: "#23232d",
              height: "100%",
            }}
          >
            <Grid
              container
              flexDirection={"column"}
              style={{ height: "100%" }}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Grid
                  style={{ height: 64, background: "white" }}
                  container
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Link to={props.logoLink}>
                    <img
                      alt={"gomymovie"}
                      src={isCollapsed ? "/logo_only.png" : "/gomymovie_logo.png"}
                    />
                  </Link>
                </Grid>
                <div
                  style={{
                    padding: 8,
                  }}
                >
                  {props.menuItems.map((button, i) => (
                    <div key={i} className={"my-2"}>
                      <SideBarButton
                        key={i}
                        icon={button.icon}
                        link={button.link}
                        collapsed={isCollapsed}
                      >
                        <span className={"text-align-center"}>
                          {button.text}
                        </span>
                      </SideBarButton>
                    </div>
                  ))}
                </div>
              </Grid>
              {typeof props.menuItems2 !== "undefined" && (
                <Grid item style={{ padding: 8 }}>
                  {props.menuItems2.map((menuItem) => (
                    <div key={menuItem.text}>
                      <SideBarButton
                        className={"nav-link-hover"}
                        icon={menuItem.icon}
                        link={menuItem.link}
                        collapsed={isCollapsed}
                      >
                        <span className={"text-align-center"}>
                          {menuItem.text}
                        </span>
                      </SideBarButton>
                    </div>
                  ))}
                </Grid>
              )}
            </Grid>
          </div>
        </Sider>
      </div>
      <Layout>
        <UserAppBar toolbar={props.toolbar} />
        <Content
          style={{ backgroundColor: "white" }}
          className={"container-fluid p-0 m-0"}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: 0,
              margin: 0,
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {props.content}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
