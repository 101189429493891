import { FC } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { blackAndWhite } from "../../../../styles";

export const QuizResultDialog: FC<{
  data?: {
    score: number;
    status: "success" | "fail";
  };
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const src =
    props.data?.status === "success"
      ? "/images/quiz_success.svg"
      : "/images/quiz_fail.svg";

  const status = props.data?.status;
  const score = props.data?.score;

  return (
    <Dialog
      open={props.isOpen}
      PaperProps={{
        sx: {
          borderRadius: 6,
        },
      }}
    >
      <DialogContent>
        {props.data && (
          <>
            <img src={src} style={{ width: 523, height: 342 }} />
            <Stack spacing={4} alignItems={"center"}>
              <Stack spacing={2}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: status === "success" ? "#23232d" : "red",
                    fontSize: 40,
                    fontWeight: "bold",
                  }}
                >
                  {score}%
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {status === "success"
                    ? "QCM complété avec succès"
                    : "Votre score n'est pas suffisant pour continuer"}
                </Typography>
              </Stack>
              <Button {...blackAndWhite} onClick={props.onClose}>
                {status === "success" ? "Suivant" : "Recommencer"}
              </Button>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
