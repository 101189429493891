import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { CSSProperties, FC, ReactElement, useEffect, useState } from "react";
import { LocalizationIcon } from "../../../icons/LocalizationIcon";
import { BagIcon } from "../../../icons/BagIcon";
import { getAllStudentsData, getListRecruteurs } from "../../../api";
import { getUrl } from "../../../utils/utils";
import { GroupOfUsersIcon } from "../../../icons/GroupOfUsersIcon";
import { useQuery } from "@tanstack/react-query";
import { getAll } from "../../../api/club.service";

const Students = () => {
  const [studentsData, setStudentsData] = useState([]);

  useEffect(() => {
    getAllStudentsData().then(async (data: any) => {
      await setStudentsData(data.data);
    });
  }, []);
  return (
    <Grid container spacing={2}>
      {studentsData.map((student: any, i) => (
        <Grid item key={i} style={{ marginBottom: 70 }} className={"col-3"}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Avatar
              style={{
                width: 200,
                height: 200,
              }}
              src={getUrl(student?.student?.userImage)}
            />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "#023047",
                marginTop: 30,
              }}
            >
              {student?.student?.prenom} {student?.student?.nom}
            </Typography>
          </Grid>
        </Grid>
      ))}{" "}
    </Grid>
  );
};

const Clubs = () => {
  const query = useQuery({
    queryKey: ["getClubs"],
    queryFn: () => getAll().then((response) => response.data),
  });

  return (
    <div>
      <Grid container spacing={2}>
        {query.data?.map((club) => (
          <Grid item key={club.id} style={{ marginBottom: 66 }}>
            <Card
              style={{
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: 28,
              }}
            >
              <img
                src={getUrl(club.image)}
                style={{ width: 200, height: 200 }}
              />
            </Card>
            <div
              style={{
                marginTop: 23,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography style={{ color: "#023047", fontWeight: "bold" }}>
                {club.nom}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const RecruiterInfo: FC<{
  icon: ReactElement;
  value: string;
}> = (props) => {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item>{props.icon}</Grid>
      <Grid item>
        <Typography
          style={{
            color: "#707070",
          }}
        >
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Recruiters = () => {
  const [companies] = useState(
    Array.from({ length: 20 }, () => ({
      name: "GoodCity Architect",
      place: "Soukra, Tunisia",
      field: "Architectes",
      employees: "10-20 employés",
      imageUrl:
        "https://seeklogo.com/images/B/business-company-logo-C561B48365-seeklogo.com.png",
    }))
  );

  const [recruteurs, setRecruteurs] = useState([]);

  useEffect(() => {
    getListRecruteurs().then(async (data: any) => {
      await setRecruteurs(data.data);
    });
  }, []);
  return (
    <div>
      <Grid container spacing={2}>
        {recruteurs.map((company: any, i) => (
          <Grid item key={i} className={"col-4"}>
            <Card
              variant={"outlined"}
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #E8E8E8",
                borderRadius: 28,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 20,
                  boxShadow: "0px 3px 6px #00000029",
                  border: "2px solid #E8E8E8",
                  borderRadius: 28,
                }}
              >
                <img
                  src={getUrl(company.image)}
                  style={{
                    width: 125,
                    height: 125,
                  }}
                />
              </div>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "#23232d",
                  }}
                >
                  {company.nom}
                </Typography>
                <Divider />
                <Box sx={{ "& div:not(:last-child)": { marginBottom: 0.5 } }}>
                  <RecruiterInfo
                    icon={<LocalizationIcon />}
                    value={company?.adresse}
                  />
                  <RecruiterInfo icon={<BagIcon />} value={company.domaine} />
                  <RecruiterInfo
                    icon={<GroupOfUsersIcon />}
                    value={company?.nombreEmploye + " employés"}
                  />
                </Box>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const Community = () => {
  const [activated, setActivated] = useState("students");
  const nonActivatedStyle: CSSProperties = {
    backgroundColor: "#FAFAFA",
    border: "1px solid #E8E8E8",
    color: "black",
    fontWeight: "bold",
    paddingLeft: 40,
    paddingRight: 40,
  };

  const activatedStyle: CSSProperties = {
    boxShadow: "0px 2px 16px #4B556E33",
    border: "none",
    color: "black",
    fontWeight: "bold",
    paddingLeft: 40,
    paddingRight: 40,
  };

  return (
    <div className={"m-5"}>
      <Grid container justifyContent={"center"} style={{ marginBottom: 50 }}>
        <Grid item>
          <Button
            variant="outlined"
            style={
              activated === "students" ? activatedStyle : nonActivatedStyle
            }
            onClick={() => {
              setActivated("students");
            }}
          >
            Etudiants
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={"outlined"}
            style={
              activated === "recruiters" ? activatedStyle : nonActivatedStyle
            }
            onClick={() => {
              setActivated("recruiters");
            }}
          >
            Recruteurs
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={"outlined"}
            style={activated === "clubs" ? activatedStyle : nonActivatedStyle}
            onClick={() => {
              setActivated("clubs");
            }}
          >
            Clubs
          </Button>
        </Grid>
      </Grid>
      <div>
        {activated === "students" && <Students />}
        {activated === "recruiters" && <Recruiters />}
        {activated === "clubs" && <Clubs />}
      </div>
    </div>
  );
};
